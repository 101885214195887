<template>
<div class="mainform">
  <div class="form">
    <el-form ref="form" class="content bascform" label-width="110px">
        <div>
            <el-form-item label="选择投标单位">
               <el-checkbox-group v-model="checkList">
                <div class="listrow" :key="index" v-for="(item,index) in SupplierList">
                  <el-checkbox :label="item.code">{{item.name}}</el-checkbox>
                  <el-input v-if="checkList.indexOf(item.code)>=0" style="margin-left:10px;" v-model="item.bz" placeholder="请填写备注"></el-input>
                </div>
              </el-checkbox-group>
            </el-form-item>
        </div>
        <div>
            <el-form-item label="复标截止日期">
              <el-date-picker v-model="bidEndDateStr" type="datetime" placeholder="复标截止日期"></el-date-picker>
            </el-form-item>
        </div>
    </el-form>
  </div>
</div>
</template>

<script>
import Component from '@/assets/js/components.js'
export default {
  name: 'RepetBid',
  components: Component.components,
  props: ['formData'],
  data: function () {
    return {
      SupplierList: this.formData.bidSupplierList,
      checkList: [],
      bidEndDateStr: this.formData.bidEndDateStr
    }
  }
}
</script>

<style scoped lang="scss">
 @import '@/assets/css/elForm.scss';
 .listrow{
   display: flex;
 }
</style>
